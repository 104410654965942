import React from 'react';
import FooterBackground from '../../images/footer-pattern-sm.png';
import Logo from '../../images/kinloch-logo.svg';
import StarLogo from '../../images/10StarLogo.svg';
import FooterStyle from './footer-style'

export default function FooterMidAM() {
  return (
    <>
      <FooterStyle style={{ backgroundImage: `url(${FooterBackground})` }}>
        <div className="footer-inner">
          <div className="container">
            <div className="row">
              <div className="col-sm-12 col-md-6 footerInfo">
                <div className="leftSection">
                  <img className="logoStyle" src={Logo} alt="Kinloch" />
                  <div className="right">
                    <div className="address">
                      <a href='https://goo.gl/maps/nmHLVPwQcvXpLk8p6' target='_blank' rel='noreferrer' aria-label='Go to Google Maps' >
                        100 Kinloch Lane <br />
                        Manakin Sabot, VA 23103
                      </a>
                    </div>
                    <div className="phone">
                      <a href='tel:8047848000' rel='noreferrer' aria-label='Call 804.784.8000'>
                        (804) 784-8000
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 footerInfo">
                <div className="rightSection">
                  <div className="left">
                    <div className="address">
                      <a href='https://maps.app.goo.gl/Wp5fr5eLickUBsb59' target='_blank' rel='noreferrer' aria-label='Go to Google Maps' >
                        600 Founders Bridge Blvd. <br />
                        Midlothian, VA 23113
                      </a>
                    </div>
                    <div className="phone">
                      <a href='tel:8046018600' rel='noreferrer' aria-label='Call 804.601.8600'>
                        (804) 601-8600
                      </a>
                    </div>
                  </div>
                  <img className="logoStyle" src={StarLogo} alt="10 Stars" />
                  {/* <div className="copyright">
                    &copy; {new Date().getFullYear()} Kinloch Golf Club. All Rights Reserved.
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div> 
      </FooterStyle>
    </>
  );
}

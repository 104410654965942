import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
  :root {
    --blue: #0071ce;
    --darkblue: #12284C; /* rgb(18,40,76) */
    --yellow: #ebaa00;
    --white: #fff;
    --OpenSansRegular: 'OpenSansRegular', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --OpenSansLight: 'OpenSansLight', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --OpenSansBold: 'OpenSansBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --Copperplate: 'Copperplate', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    --CopperplateBold: 'CopperplateBold', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  }

  body, html{
    margin: 0 !important;
    padding: 0 !important;
  }

  p {
    margin-top: 0;
    margin-bottom: 2rem!important;
  }

  p:last-child {
    margin-top: 0;
    margin-bottom: 0!important;
  }

  section {
    padding: 5rem 0 0;
  }

  section:last-child {
    /* padding: 5rem 0; */
    padding: 0;
  }
  
  /* MDB Button */
.btn {
  color: #12284C;
  text-transform: uppercase;
  vertical-align: bottom;
  border: 0;
  box-shadow: none!important;
  font-weight: 500;
  padding: .5rem 1.5rem!important;
  font-family: 'Copperplate', sans-serif;
  font-size: 0.8rem!important;
  line-height: 1.5;
}

.btn-lg {
    color: #12284C;
    text-transform: uppercase;
    vertical-align: bottom;
    border: 0;
    box-shadow: none!important;
    font-weight: 500;
    padding: .5rem 4.5rem!important;
    font-family: 'Copperplate',sans-serif;
    font-size: 0.875rem!important;
    line-height: 1.5;
}

.btn-primary {
  background-color: #0071ce;
  background-color: var(--blue);
  color: white;
  color: var(--white);
}

.btn-primary:hover {
  background-color: #12284C;
  background-color: var(--darkblue);
}

.btn-light {
  color: #12284C!important;
  background-color: white;
  background-color: var(--white);
  box-shadow: none!important;
}

/* MUI Classes */
.MuiButton-label {
  width: 100%;
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  font-family: 'Copperplate', sans-serif;
}

/* Homepage Video */
.video-background {
  display: block;
  z-index: 1;
  height: calc(100vh - 11.5rem);
  width: 100%;
  object-fit: cover!important;
  object-position: center;
}

@media (min-width: 768px) { 
  .video-background {
    display: block;
    z-index: 1;
    height: calc(100vh - 11.5rem);
    width: 100%;
    object-fit: cover!important;
    object-position: center;
  }
}

 @media (max-width: 575px) {
  h1 {
    margin-top: 0;
    margin-bottom: 0.5rem;
    font-weight: 500;
    line-height: 1.6;
    font-size: 2rem!important;
    padding: 4rem 0 0!important;
  }
  .video-background {
    display: block;
    z-index: 1;
    height: calc(100vh - 11.5rem);
    width: 100%;
    object-fit: cover!important;
    object-position: center;
  }
}

`

export default GlobalStyles;
module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://wordpress-api.kinlochgolfclub.com/graphql","verbose":true,"useACF":true,"hostingWPCOM":false,"includedRoutes":["**/categories","**/posts","**/pages","**/media","**/tags","**/taxonomies","**/users","**/menus"],"schema":{"queryDepth":5,"typePrefix":"Wp","timeout":30000,"requestConcurrency":5,"previewRequestConcurrency":2},"develop":{"nodeUpdateInterval":3000,"hardCacheMediaFiles":false},"production":{"hardCacheMediaFiles":false},"debug":{"graphql":{"showQueryOnError":false,"showQueryVarsOnError":true,"copyQueryOnError":true,"panicOnError":true,"onlyReportCriticalErrors":true}},"excludeFields":["blocksJSON","saveContent"],"type":{"Post":{"limit":5000},"CoreParagraphBlockAttributes":{"exclude":true},"CoreParagraphBlockAttributesV2":{"exclude":true},"CorePullquoteBlockAttributes":{"exclude":true}}},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/Users/BusinessMac/Documents/kinloch-2021"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kinloch Golf Club","short_name":"kinloch","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"a58b7e1f3dd65cc3bac2082efb664036"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-9QK73S42FX"],"pluginConfig":{"head":true}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]

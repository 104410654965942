import React, { useEffect} from "react";
import { Link } from 'gatsby';
import logo from '../../images/2024 U.S. MID-AMATEUR_FULL COLOR LOGO.png';
import HeaderBackground from '../../images/footer-pattern-sm.png';
// import NavMidAMInfo from '../nav/nav-midAM-info';
import HeaderStyle from './header-style';

export default function HeaderMidAMInfo() {  
  // const [header, setHeader] = useState('header')

  // const listenScrollEvent = (event) => {
  //   if (window.scrollY < 73) {
  //     return setHeader('header')
  //   } else if (window.scrollY > 70) {
  //     return setHeader('headeralt')
  //   } 
  // }

  useEffect(() => {
    // window.addEventListener('scroll', listenScrollEvent);
  
    // return () =>
    //   window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <>
      <HeaderStyle>
        <div className={'header mid-am-info'} style={{ backgroundImage: `url(${HeaderBackground})` }}>
          <div className="logo-nav-info mid-am-info container">
            <div className="logo-container mid-am-info">
              <Link to="/" aria-label='Go to guest home'>
                <img className="logoStyle mid-am-info" src={logo} alt="Kinloch 2024 Mid Am" />
              </Link>
            </div>
            {/* <NavMidAMInfo /> */}
          </div>
        </div>
      </HeaderStyle>
    </>
  );
} 
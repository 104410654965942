import React, { useState } from "react";
import {graphql, StaticQuery, Link} from 'gatsby';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';

import './nav-style.scss'

export default function Menu() {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);
  return (
    <>
      <StaticQuery 
              query={graphql`
              {
              allWpMenu(filter: {
                name: {
                  eq: "2024 Mid Am Header Menu"
                  }
                }) {
                edges {
                  node {
                    name
                    menuItems {
                      nodes {
                        label
                        url
                      }
                    }
                  }
                }
              }
            }
            `} render={data => (
                <div className="nav-mid-am" id="nav-mid-am">
                  <div className="cta">
                    <h2>Welcome to the</h2>
                    <h2>2024 U.S. Mid-Amateur Championship</h2>
                    <h4>September 19th to 26th, 2024</h4>
                  </div>
                  <ul className={click ? "nav-options mid-am active" : "nav-options mid-am"}>
                      <li className="option mobile-option" >
                        <Link 
                          to='/' 
                          onClick={closeMobileMenu} 
                          aria-label='Go to home'>
                          Home
                        </Link>
                      </li> 
                    {data?.allWpMenu?.edges[0]?.node.menuItems.nodes.map(node => (
                      <li className="option" key={node.label} id={node.label}>
                        <Link 
                          to={`${node.url}`} 
                          target="blank" 
                          onClick={closeMobileMenu} 
                          aria-label={`Go to ${node.label}`}>
                          {node.label}
                        </Link>
                      </li>
                    ))}
                      {/* <li className="option mobile-option mobile-login">
                        <a
                          href="https://kinlochgc.clubhouseonline-e3.com/Login?returnurl=%2fMember-Central"
                          target="_blank" 
                          rel="noreferrer"
                          className="btn btn-light btn-rounded" 
                          role="button" 
                          data-mdb-toggle="button">
                          Member Login
                        </a>
                      </li> */}
                    </ul>
                    {/* <ul className="member-login">
                      <li >
                        <a
                          href="https://kinlochgc.clubhouseonline-e3.com/Login?returnurl=%2fMember-Central"
                          target="_blank" 
                          rel="noreferrer"
                          className="btn btn-light btn-rounded" 
                          role="button" 
                          data-mdb-toggle="button">
                          Member Login
                        </a>
                      </li>
                    </ul> */}
                </div>
              )}/>  
              <Button className="mobile-menu" style={{marginLeft: `-14px`}} onClick={handleClick}>
                {click ? (
                  <CloseIcon className="menu-icon" />
                ) : (
                  <MenuIcon className="menu-icon" />
                )}
              </Button>
    </>
  );
}
/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */


import React from 'react';
import Layout from './src/components/layout/layout';
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';

export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>;
}

export function wrapRootElement({ element }) {
  const client = new ApolloClient({
    uri: 'https://wordpress-api.kinlochgolfclub.com/graphql',
    cache: new InMemoryCache()
  });

  if(typeof window !== "undefined") {
    return (
      <ApolloProvider client={client}>
        {element}
      </ApolloProvider>
    );
  }
  
  return null;
}


// You can delete this file if you're not using it

import React, { useState, useEffect} from "react";
import { Link } from 'gatsby';
import logo from '../../images/2024 U.S. MID-AMATEUR_FULL COLOR LOGO.png';
import HeaderBackground from '../../images/footer-pattern-sm.png';
import NavMidAM from '../nav/nav-midAM';
import HeaderStyle from './header-style';

export default function HeaderMidAM() {  
  const [header, setHeader] = useState('header')

  const listenScrollEvent = (event) => {
    if (window.scrollY < 73) {
      return setHeader('header')
    } else if (window.scrollY > 70) {
      return setHeader('headeralt')
    } 
  }

  const updateMenu = () => {
    if (document.getElementById('nav-mid-am') === undefined || document.getElementById('nav-mid-am') === null || document.getElementById('Championship History') === null)
      return;
    const gapSize = (document.getElementById('nav-mid-am').offsetWidth - document.getElementById('Championship History').offsetWidth - document.getElementById('Spectator Information').offsetWidth - document.getElementById('Volunteering').offsetWidth) / 2;
    const size1 = document.getElementById('Kinloch Golf Club').children[0].offsetWidth;
    const size2 = document.getElementById('Independence Golf Club').children[0].offsetWidth;

    document.getElementById('Kinloch Golf Club').style.paddingLeft = document.getElementById('Championship History').offsetWidth - (size1 - gapSize) / 2 + 'px';
    document.getElementById('Independence Golf Club').style.paddingRight = document.getElementById('Volunteering').offsetWidth - (size2 - gapSize) / 2 + 'px';
  }
  
  useEffect(() => {
    if (document.getElementById('nav-mid-am') !== undefined && document.getElementById('nav-mid-am') !== null)
      updateMenu();

    window.addEventListener('scroll', listenScrollEvent);
    window.addEventListener('load', updateMenu);
    window.addEventListener('resize', updateMenu);
  
    return () =>
      window.removeEventListener('scroll', listenScrollEvent);
  }, []);

  return (
    <>
      <HeaderStyle>
        <div className={header + ' mid-am'} style={{ backgroundImage: `url(${HeaderBackground})` }}>
          <div className="logo-nav mid-am container">
            <div className="logo-container mid-am">
              <Link to="/" aria-label='Go to guest home'>
                <img className="logoStyle mid-am" src={logo} alt="Kinloch 2024 Mid Am" />
              </Link>
            </div>
            <NavMidAM />
          </div>
        </div>
      </HeaderStyle>
    </>
  );
} 
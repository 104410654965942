import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {  // Blue
      main: '#0071ce',
      light: '#5c9fff',
      dark: '#00479c', 
      contrastText: '#fff',
    },
    secondary: { // Yellow
      main: '#ebaa00',
      light: '#ffdb49',
      dark: '#b47b00', 
      contrastText: '#000', 
    },
    white: {
      main: '#fff',
    }
  },
});

export default theme;
import React from 'react';
import GlobalStyles from '../../styles/global-styles';
import { ThemeProvider } from '@material-ui/styles';
import theme from '../../styles/theme';
import 'normalize.css';
import '../../styles/global-reset.scss';
import Header from '../header/header';
import HeaderMidAM from '../header/header-midAM';
import HeaderMidAMInfo from '../header/header-midAM-info';
import Footer from '../footer/footer';
import FooterMidAM from '../footer/footer-midAM';
import LayoutWrapper from './layout-style';

const Layout = ({ path, children }) => (
 <>
  <GlobalStyles />
    <ThemeProvider theme={theme}>
      {(path === "/2024-mid-am") && <HeaderMidAM />}
      {path === "/2024-mid-am-information" && <HeaderMidAMInfo />}
      {(path !== "/2024-mid-am" && path !== "/2024-mid-am-information") && <Header />}
      <LayoutWrapper>
        { children }
      </LayoutWrapper>
      {(path === "/2024-mid-am" || path === "/2024-mid-am-information") && <FooterMidAM />}
      {path !== "/2024-mid-am" && path !== "/2024-mid-am-information" && <Footer />}
    </ThemeProvider>
 </>
);

export default Layout